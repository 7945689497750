const translations = {
  // Hero page
  companyName: 'Larun Paalu',
  close: 'Sulje',
  introText: 'Larun Paalu tarjoaa merellisiä kokemuksia veneellä tai vesijetillä',
  title1: 'Veneitä ja',
  title2: 'vesijettejä',
  jetskiCta: 'Vuokraa vesijetti',
  boatCta: 'Vuokraa vene',
  smallBoatCta: 'Vuokraa pienempi vene',
  boats: 'Veneet',
  jetskis: 'Vesijetit',
  company: 'Yritys',
  // Pricing
  boatsTitle: 'Falcon BR6 / Flipper 600 SC',
  boatsDescription: 'Veneet sopivat mainiosti saaristosta nauttimiseen',
  weekday: 'Arkipäivä alk.',
  weekdayDescription:
    'Arkipäivät ovat merellä rauhallisia ja saat nauttia luonnosta. Poikkea vaikka Pihlajasaareen päivävisiitille.',
  perDay: '/päivä',
  weekendDay: 'Viikonloppu päivä alk.',
  weekendDescription: 'Viikonloppusin merellä on meinikiä, käy vaikka Skifferillä pizzalla!',
  additionalDays: '+ Lisäpäivät',
  additionalDescription: 'Tee pidempi reissu ja lähde vaikka saareen telttailemaan.',
  flipperCta: 'Varaa Flipper',
  falconCta: 'Varaa Falcon',
  feature1: 'Vene käytössäsi klo 9-21',
  feature2: {
    label: 'Käyttöohjevideo Falconista',
    link: 'https://www.youtube.com/watch?v=cz8mj7lYEss',
  },
  feature3: {
    label: 'Käyttöohjevideo Flipperistä',
    link: 'https://www.youtube.com/watch?v=3krimSUcCqQ',
  },
  featureAlternative1: 'Vene käytössäsi ensimmäisen päivänä klo 9',
  featureAlternative2: 'Viimeisenä päivänä klo 21 saakka',
  mostPopular: 'Suosituin!',
  // Small boat pricing
  smallBoatsTitle: 'Falcon BR5',
  smallBoatsDescription: 'Mainion kompakti vene. Sopii hyvin myös aloittelijalle!',
  falcon5Cta: 'Varaa Falcon BR5',
  // Jetskipricing
  jetTitle: 'Sea-Doo GTI 170',
  jetDescription:
    'Vesijeteillä nautit vauhdista ja luonnon aalloista. Meiltä löytyy 4 kpl vesijettejä jotka voit vuokrata samaan aikaan.',
  jetCta: 'Varaa tästä',
  jetFeatures1: 'Karttaplotteri',
  jetFeatures2: '170hp hauskaa',
  jetFeatures3: {
    link: 'https://www.youtube.com/watch?v=L4j_AT1VG24',
    label: 'Katso käyttöohjevideo jetistä',
  },
  jetTitle1: '2 tuntia',
  jetPrice1: '120',
  jetDescription1: 'Lähde nauttimaan meren aalloista',
  jetFrequency1: '',
  jetTitle2: '3 tuntia',
  jetPrice2: '160',
  jetDescription2:
    'Lähde kaverin kanssa seikkailulle merellä. Kolmessa tunnissa jetillä kerkeää aika pitkälla ja takaisin',
  jetFrequency2: '',
  jetTitle3: '+ Lisätunnit',
  jetPrice3: '+40',
  jetFrequency3: '/tunti',
  jetDescription3: 'Lähde pidemmälle reissulle kavereiden kanssa.',
  // Calendar
  calendarTitle: 'Vesijettien saatavuus',
  calendarDescription: 'Voit tarkistaa alta vesijettien varaustilanteen. Meiltä löytyy 4 kpl vesijettiä.',
  // Footer
  footerBoats: 'Veneiden vuokraus',
  footerJetski: 'Vesijetittien vuokraus',
  footerDescription: 'Larun Paalu tarjoaa merellisiä kokemuksia veneellä tai vesijetillä.',
  services: 'Palvelut',
  copyright: '2021 Helsingin Paalu Oy - 3209320-5',
}

exports.t = (key) => translations[key]
